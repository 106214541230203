
<!-- atendance -->
<template>
  <div class="section-nopad">
    <Section :title="title1" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data1" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit1(item)"></i>
        </li>
      </ul>
    </Section>
    <Section :title="title2" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data2" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit2(item)"></i>
        </li>
      </ul>
    </Section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title1: "Attendance",
      title2: "Visitor",
      data1: [{ label: "Health check", value: "healthCheck" }],
      data2: [{ label: "Relationship", value: "relationship" }],
    };
  },
  methods: {
    handleEdit1(item) {
      this.$router.push({
        name: "settingAttendanceInfo",
        query: {
          title: item.label,
          moduleType: item.value,
          moduleName: "attendance",
          activeIndex: "settingAttendance",
        },
      });
    },
    handleEdit2(item) {
      this.$router.push({
        name: "tablePage",
        query: {
          title: item.label,
          moduleType: item.value,
          moduleName: "visitor",
          activeIndex: "settingAttendance",
        },
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>